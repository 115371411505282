<template>
  <div style='display: inline;;font-size: 14px'>
    <el-tooltip content="点击查看产品信息的更新记录和同步情况" placement="top" effect="light">
      <el-button size='medium' icon='el-icon-time' type='text' @click='handleShow' class="update" >更新记录</el-button>
    </el-tooltip>

    <el-drawer :visible.sync='show' :title='`【${ProductName}】历史版本`' custom-class='product-version-list-drawer'>
      <div class='product-version-list-drawer__main'>
        <el-menu :key='active||"menu"' :default-active='active' @select='eventSelect' background-color='#fff'
                 text-color='#333'
                 active-text-color='#ff3176'>
          <el-menu-item>
            <span slot='title'>共 {{ list.length }} 条更新版本</span>
          </el-menu-item>
          <el-menu-item v-for='item in list' :index='item.version' :key='item.version'>
            <div slot='title' style='width: 150px;display: flex;justify-content: space-between;'>
              <span>{{ item.created_at|parseTime('{m}-{d} {h}:{i}') }}</span>
              <span style='font-size: 12px;color: #777888;'>{{ item.create_user ? item.create_user.name : '' }}</span>
            </div>
          </el-menu-item>
        </el-menu>
        <div style='margin-left: 10px;border-left: 1px solid #f2f2f2;padding:0 10px;width: calc(100% - 180px);'>
          <el-descriptions border>
            <el-descriptions-item label='修改时间'>{{ version.created_at|parseTime('{y}-{m}-{d} {h}:{i}') }}
            </el-descriptions-item>
            <el-descriptions-item label='修改人'>{{ version.create_user ? version.create_user.name : '' }}
            </el-descriptions-item>
            <el-descriptions-item label='版本号'>{{ version.version || '' }}</el-descriptions-item>
          </el-descriptions>
          <el-table ref='fields' :data='fields' style='width: calc(100% - 20px);'>
            <el-table-column prop='_field' label='修改项目' align='center' wi dth='110' show-overflow-tooltip />
            <el-table-column label='修改内容' align='center'>
              <template v-slot='{row:{_value}}'>
                <div v-html='_value' />
              </template>
            </el-table-column>
            <el-table-column label='同步状态' align='center' width='130'>
              <template v-slot='{row:{_is_sync}}'>
                {{ _is_sync }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  name: 'ProductVersionList',
  props: {
    ProductId: {
      type: [Number, String],
      required: true
    },
    ProductName: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      show: false,
      list: [],
      active: null,
      fields: [],
      version: {}
    }
  },
  watch: {
    active() {
      this.getDetail()
    }
  },
  methods: {
    eventSelect: function(index) {
      this.active = index
    },
    getList: async function() {
      const list = await this.$api.getProductVerList({ product_id: this.ProductId })
      this.list = list || []
      if (Array.isArray(list) && list.length) {
        this.active = list[0].version
        await this.getDetail()
      }
    },
    getDetail: async function() {
      this.fields = []
      this.version = {}
      const { changes, base } = await this.$api.getProductVerDetail({ version: this.active })
      this.version = base
      this.fields = changes || []
    },
    handleShow: function() {
      this.getList()
      this.show = true
    }
  }
}
</script>
<style lang='scss'>
.product-version-list-drawer {
  width: 1000px !important;
  font-family: FjallaOne, DINPro Medium, sans-serif !important;

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px !important;
  }

  &__main {
    display: flex;
    padding: 20px;
  }

  .el-menu-item {
    border-bottom: 1px solid #f2f2f2;
    margin: unset !important;
  }
}
.update{
  color:$--color-text-regular;
}
</style>
