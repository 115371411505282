<template>
  <div style='display: inline;'>
    <el-button icon='el-icon-delete-solid' @click='handleShowDrawer(false)'>
      回收站
    </el-button>
    <el-drawer :visible.sync='show' title='产品库回收站' :before-close='handleHideDrawer'
               custom-class='product-recycle-drawer'>
      <div style='display: flex;align-items: center;margin-top: 32px;'>
        <div
          style='text-align: center;width: calc(100% - 100px);display: grid;grid-template-columns: repeat(3, calc((100% - 20px) / 3));grid-column-gap: 10px;'>
          <el-input placeholder='产品名称搜索' v-model='search.title' clearable @change='handleSearch' />
          <el-select clearable v-model='search.brand_id' filterable style='width: 100%' @focus='getBrand'
                     placeholder='品牌搜索' @change='handleSearch'>
            <el-option
              v-for='item in brandOptions'
              :key='item.id'
              :label='item.name'
              :value='item.id' />
          </el-select>
          <template>
            <product-leader-select v-if='hasAuthority("product_view_all")' :leader-id.sync='search.leader_id'
                                   @selected='handleSearch' style='margin-left: -20px;' />
            <span v-else>{{ userInfo.nickname }} </span>
          </template>
        </div>
        <el-button type='primary' icon='el-icon-search' @click='handleSearch(false)' style='width: 80px;'>
          搜索
        </el-button>
      </div>
      <div v-if='list_mode==="BigPic"' class='products'>
        <div v-for='(product,index) in products' :key='product.id'
             class='product' @mouseenter='hover=index' @mouseleave='hover=null'>
          <div class='product-cell'>
            <div class='product-hover' :style='`${index>7?"top: -110%":""}`'>
              <div v-if='index===hover' class='product-fixed_card'>
                <div style='margin-top: 152%;' />
                <span style='height: calc(100% - 173px);left: 0;' class='product-img product-fixed_card_avatar  '>
                    <el-image style='width: 100%; height: 100%' :src='product.cover_url' />
                </span>
                <div class='product-delete_time'>
                  删除于&nbsp;&nbsp;{{ product.deleted_at|parseTime('{y}/{m}/{d} {h}:{i}') }}
                </div>
                <div class='product-delete_time' style='top: 50px'>
                  添加于&nbsp;&nbsp;{{ product.created_at|parseTime('{y}/{m}/{d} {h}:{i}') }}
                </div>
                <el-button type='primary' @click='handleUnDelete(product)'
                           style='display: block;margin: 0 auto 12px auto;'>
                  <i class='el-icon-refresh-left' />
                  还原
                </el-button>
                <div class='product-fixed_goods_info'>
                  <div><span class='product-one_line'>{{ product.price }}</span><span>日常价</span></div>
                  <div><span class='product-one_line'>{{ product.lb_price }}</span><span>直播价</span></div>
                </div>
                <div class='product-fixed_card_text' style='line-height: 1'>
                  {{ product.title }}
                </div>
                <div class='product-fixed_shop_info'>
                  <div class='product-flex_base' style='cursor: pointer;'>
                    <i class='anticon' style='color: rgb(254, 52, 110); margin-right: 6px; font-size: 16px;'>
                      <svg width='1em' height='1em' fill='currentColor' aria-hidden='true' focusable='false' class=''>
                        <use xlink:href='#icon-dianpu'></use>
                      </svg>
                    </i>
                    <div class='product-one_line' style='width: 100px;'>{{ product.company_name }}</div>
                  </div>
                  <div v-if='product.company_score' class='product-gray_color_12'>
                    体验分&nbsp;<span style='color: rgb(69, 71, 77);'>{{ product.company_score|numberFormat(1) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style='margin-top: 100%;' />
            <div>
              <span class='product-img product-index-card_image  ' style='left: 0;'>
                    <el-image style='width: 100%; height: 100%' :src='product.cover_url' />
              </span>
              <div class='product-shadow_box'
                   style='position: absolute; left: 10px; top: 10px;'>
                {{ product.deleted_at|parseTime('{m}/{d} {h}:{i}') }}
              </div>
            </div>
            <div class='product-index-card_text'>
              <div class='product-one_line' style='font-weight: 500;-webkit-line-clamp: 2'>
                {{ product.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-table v-else :data='products' ref='products' height='calc(100% - 150px)' style='margin-top: 20px;'>
        <el-table-column type='selection' width='55' />
        <el-table-column prop='deleted_at' label='删除时间' width='100' show-overflow-tooltip />
        <el-table-column prop='title' label='产品名称'>
          <template v-slot='{row:{title}}'>
            <div style='line-height: 1.1'>{{ title }}</div>
          </template>
        </el-table-column>
        <el-table-column label='图片' width='55' align='center'>
          <template v-slot='{row:{cover_url}}'>
            <product-image style='width: 40px;height: 40px' :image-url='cover_url' />
          </template>
        </el-table-column>
        <el-table-column prop='category' label='类目' width='80' show-overflow-tooltip />
        <el-table-column prop='brand_name' label='品牌' width='70' show-overflow-tooltip />
        <el-table-column prop='leader_name' label='负责人' width='60' show-overflow-tooltip />
        <el-table-column v-if='hasAuthority("product_recycle_back")' label='操作' width='70' align='center'>
          <template v-slot='{row}'>
            <el-button type='text' @click='handleUnDelete(row)'>
              <i class='el-icon-refresh-left' />
              还原
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class='footer' style='display: flex;justify-content: space-between;padding: 0 20px;'>
        <div>
          <el-radio-group v-model='list_mode'>
            <el-radio-button label='Table'>列表</el-radio-button>
            <el-radio-button label='BigPic'>大图</el-radio-button>
          </el-radio-group>
          <el-button v-if='list_mode==="Table"&&hasAuthority("product_recycle_back")' type='danger' @click='handleBatch'
                     style='margin-left: 10px;'>
            批量还原
          </el-button>
        </div>
        <el-pagination background :page-size='pages.page_size' :current-page.sync='pages.current_page'
                       layout='prev, pager, next' :total='pages.total' />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import ProductImage from './ProductImage.vue'
import ProductLeaderSelect from '../components/ProductLeaderSelect.vue'
import { mapGetters } from 'vuex'

const { omitBy } = require('lodash')

export default {
  name: 'ProductRecycle',
  components: { ProductLeaderSelect, ProductImage },
  data() {
    return {
      show: false,
      hover: null,
      list_mode: 'Table',
      products: [],
      brandOptions: [],
      search: {
        title: null,
        brand_id: null,
        leader_id: null
      },
      pages: {
        current_page: 1,
        page_size: 12,
        total: 0
      }
    }
  },
  computed: { ...mapGetters(['userInfo']) },
  watch: {
    'pages.current_page'(_) {
      this.pages.current_page = _
      this.getList()
    }
  },
  methods: {
    handleHideDrawer: function() {
      this.show = false
      this.$emit('close')
    },
    handleShowDrawer: function() {
      this.show = true
      this.getList()
    },
    handleSearch: function() {
      this.pages.current_page = 1
      this.getList()
    },
    handleBatch: function() {
      if (!this.$refs.products || this.$refs.products.selection.length < 1) {
        this.$notify.warning('请选择要还原的行数据！')
        return
      }
      const ids = this.$refs.products.selection.map(_ => {
        return _.id
      })
      this.$confirm(`已选中${ids.length}行数据，确认批量还原吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.unDelProduct(ids)
        await this.getList()
      }).catch(() => {

      })
    },
    getList: async function() {
      let params = {
        current_page: this.pages.current_page,
        page_size: this.pages.page_size,
        is_recovery: 'Y',
        order: { deleted_at: 'desc' }
      }

      params = { ...params, ...this.search }
      params = omitBy(params, (value) => value === undefined || value === null || value === '')// 过滤空值
      if (!this.hasAuthority('product_view_all')) {
        params.leader_id = this.userInfo.id
      }
      const { list, pages } = await this.$api.getProductList(params)
      this.products = list
      this.pages = pages
    },
    handleUnDelete: function({ id, title }) {
      this.$confirm(`确认恢复被删除的${title}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.unDelProduct([id])
        await this.getList()
      }).catch(() => {

      })
    },
    async getBrand(force) {
      this.loadingBrandStatus = true
      if (this.brandOptions.length === 0 || force) {
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
        this.loadingBrandStatus = false
      }
    }
  }
}
</script>
<style lang='scss'>
.product-recycle-drawer {
  width: 800px !important;

  .el-drawer__title {
    border-top-left-radius: 10px !important;
  }

  .el-drawer__header {
    font-size: 16px !important;
    color: #333333 !important;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0 !important;
  }

  .el-drawer__body {
    border-bottom-left-radius: 10px !important;
    padding: 0 20px;
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 66px;
    opacity: 1;
    background: #fff;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 99;
  }

  .products {
    margin-left: -12px;
    margin-right: -12px;
    position: relative;
    height: auto;
    display: block;
    box-sizing: border-box;
    padding-left: 48px;
    padding-top: 56px;

    .product {
      float: left;
      cursor: pointer;
      position: relative;
      padding-left: 12px;
      padding-right: 12px;
      width: 148px;
    }
  }

  .product-cell {
    margin-bottom: 12px;
    background: transparent;
    box-shadow: none;
    border-radius: 20px;
    position: relative;
    width: 100%;
    display: inline-block;
    float: left;
  }

  .product-index-card_image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 36px);
    border-radius: 20px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .product-shadow_box {
    background: rgba(0, 0, 0, .5);
    border-radius: 16px;
    padding: 6px 10px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
  }

  .product-index-card_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 24px;
    font-size: 12px;
    padding: 6px 10px;
  }

  .product-gray_color_12 {
    font-size: 12px;
    color: #8a8e99;
  }

  //------------------
  .product-fixed_card {
    margin-bottom: 0;
    height: 100%;
  }

  .product-fixed_card_avatar {
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: calc(100% - 20px);
    height: calc(100% - 122px);
    background: #fff;
    border-radius: 20px 20px 0 0;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
  }

  .product-delete_time {
    position: absolute;
    top: 20px;
    left: 20px;
    background: rgba(0, 0, 0, .5);
    border-radius: 16px;
    padding: 6px 10px;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
  }

  //.product-cell
  .product-fixed_goods-part {
  }

  .product-fixed_goods_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: calc(100% - 160px);
    padding: 0 15px;
    width: calc(100% - 30px);

    div {
      text-align: center;
      color: #45474d;
    }
  }

  .product-fixed_card_text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
    position: absolute;
    top: calc(100% - 124px);
    padding: 0 15px;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .product-fixed_shop_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    top: calc(100% - 74px);
    position: absolute;
    padding: 0 15px;
    font-size: 12px;
    width: calc(100% - 30px);
  }

  .product-flex_base {
    //width: 60%;
    display: flex;
    align-items: center;
  }

  .product-gray_color_12 {
    font-size: 12px;
    color: #8a8e99;
  }

  .product-one_line {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-break: break-all;
  }

  .product-fixed_card {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 6px 20px 0 rgba(163, 175, 204, .8);
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    display: inline-block;
  }

  .product-fixed_goods_info div {
    text-align: center;
    color: #45474d;

    span:first-child {
      font-weight: 700;
      display: block;
      line-height: 14px;
      //margin-bottom: 6px;
    }

    span:last-child {
      font-size: 12px;
      color: #8a8e99;
    }
  }

  .el-image__error {
    border-radius: 20px;
  }


  .product-hover {
    position: absolute;
    width: 222px;
    left: -25%;
    top: -65%;
    z-index: 2;
  }
}
</style>
