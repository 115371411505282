<template>
  <div>
    <el-badge :hidden='newProductCount===0' :max='99' :value='newProductCount' class='item'>
      <el-button icon='el-icon-s-marketing' @click='addNewProduct'>选品通知</el-button>
    </el-badge>
    <el-dialog :before-close='beforeClose' :visible.sync='showNewProduct' center title='新增选品通知及确认处理'
               width='80%'
               @open='onOpen'>
      <el-form :model='searchCondition' inline size='mini' @submit.native.prevent>
        <el-form-item label=''>
          <el-radio-group v-model='searchCondition.is_mine' clearable size='mini' @change='handleQuery'>
            <el-radio-button label=''>全部</el-radio-button>
            <el-radio-button label='Y'>我负责</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label=''>
          <el-radio-group v-model='searchCondition.is_done' clearable size='mini' @change='handleQuery'>
            <el-radio-button label=''>全部</el-radio-button>
            <el-radio-button label='Y'>已处理</el-radio-button>
            <el-radio-button label='N'>未处理</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label=''>
          <el-select v-model='searchCondition.type' placeholder='排期类型' clearable size='mini' @change='handleQuery'
                     style='width: 120px'>
            <el-option value='' label='全部'>全部</el-option>
            <el-option :value='`1`' label='红人选品'>红人选品</el-option>
            <el-option :value='`2`' label='直播定品'>直播定品</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=''>
          <el-input v-model='searchCondition.product_title' clearable placeholder='请输入产品名称' @change='handleQuery' />
        </el-form-item>
        <el-form-item>
          <el-button icon='el-icon-search' type='primary' @click='handleQuery'>搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-circle-check' @click='handleState("Y")'>批量处理</el-button>
        </el-form-item>
      </el-form>
      <div class='default-table'>
        <el-table :data='dataList' border highlight-current-row @selection-change='handleSelectionChange'>
          <el-table-column type='selection' width='55'></el-table-column>
          <el-table-column align='center' label='序号' type='index' width='55'></el-table-column>
          <el-table-column align='center' label='产品名称' min-width='140' prop='product_title'
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column align='center' label='排期类型' min-width='120' prop='_type'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='排期' min-width='330' prop='lb_title' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-link type='primary' @click='routerTitle(row)'>{{ row.lb_title }}</el-link>
            </template>
          </el-table-column>
          <el-table-column align='center' label='选品人' prop='creator' width='100'></el-table-column>
          <el-table-column align='center' label='状态' prop='_is_done' width='100'>
            <template slot-scope='{row}'>
              <span :class='[row.is_done==="Y"?"deal-done":"deal-wait"]'>{{ row._is_done || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' label='创建时间/修改时间' show-overflow-tooltip width='280'>
            <template slot-scope='{row}'>
              <span>{{ row.created_at }} / {{ row.updated_at }}</span>
            </template>
          </el-table-column>
          <el-table-column align='center' fixed='right' label='操作' width='120'>
            <template slot-scope='{row}' style='display: flex'>
              <el-button v-if='row.is_done === "N"' icon='el-icon-circle-check' type='text'
                         @click='handleEdit(row, "Y")'>
                处理
              </el-button>
              <!--              <el-button v-if='row.is_done === "Y"' icon='el-icon-circle-close' type='text'-->
              <!--                         @click='handleEdit(row, "N")'>-->
              <!--                标记为【未处理】-->
              <!--              </el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <!--        分页-->
        <pagination :background='true' :hidden='total === 0' :limit.sync='pageData.page_size'
                    :page.sync='pageData.current_page' :total.sync='total' @pagination='getList' />
      </div>
    </el-dialog>
  </div>

</template>
<script>
export default {
  name: 'dialogNewProduct',
  data() {
    return {
      dataList: [],
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      searchCondition: {
        product_title: '',
        type: null,
        is_done: 'N',
        is_mine: 'Y'
      },
      stateOptions: [
        { label: '已处理', value: 'Y', type: 'success', icon: 'el-icon-circle-check' },
        { label: '未处理', value: 'N', type: 'info', icon: 'el-icon-circle-close' }
      ],
      buttonVal: 1,
      stateActive: 1,
      multiple: true,
      dropdownActive: 0,
      ids: [],
      newProductCount: 0,
      showNewProduct: false

    }
  },
  methods: {
    onOpen() {
      this.getList()
    },
    beforeClose() {
      this.showNewProduct = false
      this.getProductCount()
    },
    //处理搜索条件
    handleSearchCondition() {
      let cond = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field])
          cond[field] = this.searchCondition[field]
      })

      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    //获取表单数据
    async getList() {
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getLbProductSelectionList(searchCondition)
      this.dataList = list || []
      // this.dataList = [
      //   {
      //     name: 'text',
      //     title: '2023-1-1小红书排期',
      //     status: 1,
      //     type: 'kol',
      //     lb_id: 114
      //   },
      //   {
      //     name: 'text2',
      //     title: '2023-1-2小红书排期',
      //     status: 2,
      //     type: 'live',
      //     lb_id: 119
      //   }
      // ]
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    async handleEdit(row, status) {
      row.is_done = status
      row._is_done = status === 'Y' ? '已处理' : '未处理'
      //网络请求
      let id = await this.$api.changeLbProductSelectionStatus(row.id, status)
      if (id) {
        // this.$notify.success('操作成功')
        row.is_done = status
      }

      // //status:1.已处理,2.未处理
      // this.$confirm(`是否确认修改状态为[${status === 'Y' ? '已处理' : '未处理'}]?`, '警告', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      //   row.is_done = status
      //   row._is_done = status === 'Y' ? '已处理' : '未处理'
      //   //网络请求
      //   let id = await this.$api.changeLbProductSelectionStatus(row.id, status)
      //   if (id) {
      //     this.$message.success('操作成功')
      //   }
      // }).catch(() => {
      //   this.$message.warning('已取消')
      // })

    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    commandOptions(val) {
      this.stateActive = val
      this.handleState(this.stateOptions[this.stateActive].value)
    },

    //批量更改状态
    async handleState(val) {
      let id = await this.$api.changeLbProductSelectionStatus(this.ids, val)
      if (id) {
        this.$message.success(`批量状态[${val === 'Y' ? '已处理' : '未处理'}]修改成功`)
        await this.handleQuery()
      }
    },
    routerTitle(row) {
      row.type === 2 ? this.routerFun('lbLiveDetail', { id: row.lb_id }) : this.routerFun('lbKolDetail', { id: row.lb_id })
    },
    routerFun(name, params) {
      let routeData = this.$router.resolve({ name: name, params: params })
      window.open(routeData.href, '_blank')
    },
    addNewProduct() {
      this.showNewProduct = true
    },
    async getProductCount() {
      //获取新添选品数量badge
      let { count } = await this.$api.getLbProductSelectionCount()
      this.newProductCount = count || 0
    }

  },
  mounted() {
    this.getProductCount()
  }
}
</script>

<style>
.deal-wait {
  color: #F56C6C !important;
}

.deal-done {
  color: #409EFF !important;
}
</style>
