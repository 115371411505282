<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle" width="80%"
               append-to-body>
      <el-row :gutter="15">
        <el-col :span="12" style="text-align: center">
          <el-upload
              class="upload-demo"
              :on-change="handleChange"
              :on-success="importSuccess"
              :headers="uploadHeaders"
              name="file"
              :show-file-list="false"
              :file-list="file"
              :before-upload="beforeUpload"
              drag
              action="/admin_api/product/import"
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip"></div>
          </el-upload>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="导入模板">
              <export-link ref="refExportLink" :class-name="`import-tpl-btn`" :can-export="true"
                           @export="downLoadTemplate" :lint-title="`模板下载.xlsx`"/>
            </el-form-item>
          </el-form>
          <Tips :data="tipsList"/>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="24">
              <h4>导入数据预览</h4>
            </el-col>
          </el-row>
          <div class="default-table">
            <el-table :data="dataList"
                      height="300"
                      :row-class-name="tableRowClassName"
                      border>
              <!--              <el-table-column type="selection" width="55"></el-table-column>-->
              <el-table-column type="index" label="序号" width="65" align="center"></el-table-column>
<!--              <el-table-column label="录入时间" prop="created_at" width="120" align="center">-->
<!--                <template slot-scope="{row}">-->
<!--                  <span>{{ $utils.parseTime(row.created_at, '{y}-{m}-{d}') }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column label="商品名称" prop="title" width="160" align="center" show-overflow-tooltip>
                <template slot-scope="{row}">
                  <span>{{ row.title }}</span>
                </template>
              </el-table-column>
<!--              <el-table-column label="产品图" prop="cover" width="100" align="center">-->
<!--                <template slot-scope="{row}">-->
<!--                  <img :src="row.cover_pc_url" :alt="row.cover" width="40px"/>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <el-table-column label="类目" prop="category_id" min-width="80" align="center">
                <template slot-scope="{row}">
                  <span>{{ row.category || '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="规格" prop="spec_name" align="center" min-width="100"></el-table-column>
              <el-table-column label="合作方式" prop="mode" align="center" min-width="100">
                <template slot-scope="{row}">
                  <span>{{ row.mode_alias || '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="日常价(元)" align="center" min-width="140">
                <template slot-scope="{row}">
                  <span>{{ row.price || '/' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="直播价(元)" align="center" min-width="140">
                <template slot-scope="{row}">
                  <span>{{ row.lb_price || '/' }} </span>
                </template>
              </el-table-column>
              <el-table-column label="赠品" prop="gift" align="center" min-width="160">
                <template slot-scope="{row}">
                  <span>{{ row.gift || '/' }} </span>
                </template>
              </el-table-column>
              <el-table-column label="独家机制" prop="other_activity">
                <template slot-scope="{row}">
                  <span>{{ row.other_activity || '' }} </span>
                </template>
              </el-table-column>
              <el-table-column label="线上佣金(%)" align="center" min-width="120">
                <template slot-scope="{row}">
                  <span class="rate">{{ row.online_cmn || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="线下佣金(%)" align="center" min-width="120">
                <template slot-scope="{row}">
                  <span class="rate">{{ row.offline_cmn || '-' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="负责人" prop="leader" align="center" min-width="100">
                <template slot-scope="{row}">
                  <span>{{ row.leader_name || '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注信息" prop="remark" align="center">
                <template slot-scope="{row}">
                  <span>{{ row.remark || '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="公司" prop="company_name" align="center" min-width="100">
                <template slot-scope="{row}">
                  <span>{{ row.company_name || '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="产品链接" prop="product_url" align="center" min-width="140">
                <template slot-scope="{row}">
                  <span>{{ row.product_url || '' }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-show="showProgress">
            <el-progress :percentage="percentage"></el-progress>
          </div>
        </el-col>
      </el-row>
      <div slot="footer" style="text-align: center">

        <el-button type="primary" size="large" @click="batchSubmit" v-if="!loadingSubmit" :loading="loadingSubmit">
          确认提交
        </el-button>
        <el-button @click="close" size="large">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ExportLink from "@/components/export/ExportLink";
import {getToken} from '@/utils/auth'


export default {
  name: "ImportProductPanel",
  components: {ExportLink},
  props: {},
  data() {
    return {
      uploadHeaders: {'HYPERF-SESSION-ID': null},
      file: [],

      uploadLoading: false,
      maxSize: 5,//文件最大的 5 M
      tipsList: [
        '第1步，下载模板',
        '第2步，按照模板规范填充好数据后，根据提示上传文件（产品图列请设置为空，产品链接列可填写）',
        '第3步，预览导入的数据，确认无误后，点击“提交”按钮',
        '注意：1）导入的excel文件请务必和模板列保持顺序，否则，会出现数据混乱的情况 2）文件的大小不能超过 5M'
      ],
      dataList: [],
      submitted: [],
      loadingSubmit: false,
      showProgress: false,
    }
  },
  computed: {
    dialogTitle() {
      return '产品导入'
    },
    percentage() {
      if (this.dataList.length)
        return this.submitted.length * 100 / this.dataList.length
      else
        return 0
    }
  },
  methods: {
    onOpen() {
      this.file = []
      this.dataList = []
      this.submitted = []
      this.uploadLoading = false
      this.loadingSubmit = false
      this.showProgress = false
    },
    onClose() {
      // this.$refs['elForm'].resetFields()
      // this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    async downLoadTemplate() {
      //下载模板 downloadImportTpl
      try {
        let name = `产品导入模板`
        let response = await this.$api.downloadImportTpl({})

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    handleChange(file) {
      this.file = [file];
    },
    beforeUpload(file) {
      this.uploadLoading = true;
      const isLt2M = file.size / 1024 / 1024 < this.maxSize;
      if (!isLt2M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`);
        this.uploadLoading = false
      }

      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()

      return isLt2M;
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('上传成功，请预览数据后确认提交')
        this.submitted = []
        this.loadingSubmit = false
        this.showProgress = false
        this.dataList = response.data.list || []
      } else {
        this.$notify.warning(response.msg);
      }
    },
    batchSubmit() {
      this.loadingSubmit = true
      this.showProgress = true

      this.dataList.forEach(async (item, index) => {
        let info = await this.$api.saveProduct(item)
        if (info)
          this.submitted.push(index)
      })

      // setTimeout(() => {
      //   this.loadingSubmit = false
      // }, 5000)


    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({row, rowIndex}) {
      if (this.submitted.indexOf(rowIndex) > -1) {
        return 'success-row';
      }
      return '';
    }

  }
}
</script>

<style scoped>

.import-tpl-btn {

}
</style>
<style>
.el-table .success-row {
  background: #f0f9eb;
}
</style>