<template>
  <div class='default-table'>
    <el-row :gutter="20" style="margin: 20px;">
      <el-col :span="6">
        <el-carousel height="300px" v-if="productsData.length>0">
          <el-carousel-item v-for="(item,index) in productsData" :key="index" :interval="5000">
            <el-image
                :fit="'contain'"
                :src="item.product_cover_url"
                style="height: 220px;width: 100%">
              <div slot="error" class="image-slot">
                <svg-icon icon-class="errorImg" style="font-size: 100px"></svg-icon>
              </div>
            </el-image>
            <h3 class="small" style="text-align: center"><span style="font-size: 12px;color: #ff3176">
                【{{ index + 1 }}/{{ productsData.length }}】</span>{{ item.product_title }}</h3>
            <span>数量：{{ item.qty }}
                <el-divider direction="vertical"></el-divider>
                    规格：{{ item.product_spec_name }}
                <el-divider direction="vertical"></el-divider>
                    负责人：{{ item.leader_name }}
                <el-divider direction="vertical"></el-divider>
            </span>
          </el-carousel-item>
        </el-carousel>
        <svg-icon v-else icon-class="errorImg" style="font-size: 200px;display: block;text-align: center"></svg-icon>

      </el-col>
      <el-col :span="18">
        <el-descriptions :column="4" border direction="vertical">
          <el-descriptions-item label="快递公司">{{ infoData.com_label }}</el-descriptions-item>
          <el-descriptions-item :span="2" label="快递单号">{{ infoData.nu }}</el-descriptions-item>
          <el-descriptions-item label="是否退样">{{ infoData.need_back === 'Y' ? '是' : '否' }}</el-descriptions-item>
          <el-descriptions-item label="快递状态">
            <span :style="logisticInfo.ischeck === '1' ?{color:'red'}:{}">{{
                logisticInfo.ischeck === '1' ? '已签收' : '未签收'
              }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item label="发出地" style="overflow: hidden">{{
              logisticInfo?.route_info?.from?.name || '暂无信息'
            }}
          </el-descriptions-item>
          <el-descriptions-item label="到达地">{{
              logisticInfo?.route_info?.to?.name || '暂无信息'
            }}
          </el-descriptions-item>
          <el-descriptions-item label="最新物流信息">
            <template slot="label">
              最新物流信息
              <logistic-info :buttonTitle="'更多'" :logisticData="logisticInfo?.data||[]"
                             :morePopover.sync="morePopover" style="display: inline-block"></logistic-info>
            </template>
            {{ logisticInfo?.data?.[0].context || '暂无信息' }}
          </el-descriptions-item>
          <el-descriptions-item label="产品件数">{{ productsData.length }}</el-descriptions-item>
          <el-descriptions-item label="数量">{{ getProductsCount }}</el-descriptions-item>
          <el-descriptions-item label="产品列表">
            <span v-for="(item,index) in getProductsName" :key="index" style="display: block">{{ item }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

  </div>
</template>
<script>
import LogisticInfo from '@/components/LogisticInfo.vue'

export default {
  name: 'SpecimenInfo',
  data() {
    return {
      infoData: {},
      productsData: [],
      logisticInfo: {},
      morePopover: false
    }
  },
  components: {
    LogisticInfo
  },
  props: {
    id: {
      type: [String, Number]
    }
  },
  computed: {
    getProductsName() {
      let data = this.productsData.map((item, index) => {
        return `${index + 1}、${item.product_title}（规格：${item.product_spec_name || '/'}，数量：${item.qty || '/'} 份，负责人：${item.leader_name || '暂无'}）`
      })
      return data
    },
    getProductsCount() {
      let count = 0
      this.productsData.forEach((i) => {
        count = count + Number(i.qty)
      })
      return count
    }
  },
  methods: {
    async getList() {
      let { info } = await this.$api.getProductSpecimenInfo(this.id)
      this.infoData = info
      this.productsData = info?.products || []
      this.logisticInfo = info?.logistic_info || {}
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
