<template>
  <div>
    <el-button icon='el-icon-s-marketing' @click='addNewProduct'>产品寄样管理</el-button>
    <el-dialog :before-close='beforeClose' :visible.sync="showNewProduct" center fullscreen title='产品寄样管理'
               width='880px'
               @open='onOpen'>
      <el-form :model='searchCondition' inline size='mini' @submit.native.prevent>
        <el-form-item label=''>
          <el-radio-group v-model='searchCondition.is_mine' clearable size='mini' @change='handleQuery'>
            <el-radio-button label=''>全部</el-radio-button>
            <el-radio-button label='Y'>我负责</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label=''>
          <el-radio-group v-model='searchCondition.is_done' clearable size='mini' @change='handleQuery'>
            <el-radio-button label=''>全部</el-radio-button>
            <el-radio-button label='Y'>已签收</el-radio-button>
            <el-radio-button label='N'>未签收</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label=''>
          <el-input v-model='searchCondition.nu' clearable placeholder='请输入物流单号' @change='handleQuery'/>
        </el-form-item>
        <el-form-item>
          <el-button icon='el-icon-search' type='primary' @click='handleQuery'>搜索</el-button>
        </el-form-item>
      </el-form>
      <div class='default-table'>
        <el-table :data='dataList' highlight-current-row>
          <el-table-column type="expand">
            <template slot-scope="{row}">
              <specimen-info :id="row.id"></specimen-info>
            </template>
          </el-table-column>
          <el-table-column align='center' label='序号' type='index' width='55'></el-table-column>
          <el-table-column align='center' label='快递公司' prop='com_label' width='100'
                           show-overflow-tooltip></el-table-column>
          <el-table-column align='center' label='物流单号' prop='nu' show-overflow-tooltip>
          </el-table-column>
          <el-table-column :formatter="$utils.formatterYorN" align='center' label='是否需要退样'
                           prop='need_back'
                           show-overflow-tooltip width='100'>
          </el-table-column>
          <el-table-column align='center' label='状态' prop='_is_done' show-overflow-tooltip width='100'>
          </el-table-column>
          <el-table-column align='center' label='备注' prop='remark' show-overflow-tooltip >
          </el-table-column>
          <el-table-column align='center' label='创建时间/修改时间' show-overflow-tooltip >
            <template slot-scope='{row}'>
              <span>{{ row.created_at }} / {{ row.updated_at }}</span>
            </template>
          </el-table-column>
        </el-table>
        <!--        分页-->
        <pagination :background='true' :hidden='total === 0' :limit.sync='pageData.page_size'
                    :page.sync='pageData.current_page' :total.sync='total' @pagination='getList'/>
      </div>
    </el-dialog>
  </div>

</template>
<script>
import SpecimenInfo from '@/pages/lb/product/SpecimenInfo.vue'

export default {
  name: 'ProductSpecimen',
  components: { SpecimenInfo },
  data() {
    return {
      dataList: [],
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      searchCondition: {
        nu: '',
        is_done: 'N',
        is_mine: 'Y'
      },
      stateOptions: [
        { label: '已处理', value: 'Y', type: 'success', icon: 'el-icon-circle-check' },
        { label: '未处理', value: 'N', type: 'info', icon: 'el-icon-circle-close' }
      ],
      showNewProduct: false

    }
  },
  methods: {
    onOpen() {
      this.getList()
    },
    beforeClose() {
      this.showNewProduct = false
    },
    //处理搜索条件
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.is_done) {
        cond.is_done = this.searchCondition.is_done
      }
      if (this.searchCondition.is_mine) {
        cond.is_mine = this.searchCondition.is_mine
      }
      if (this.searchCondition.nu) {
        cond.nu = this.searchCondition.nu
      }
      return cond
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    //获取表单数据
    async getList() {
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.getProductSpecimenList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    addNewProduct() {
      this.showNewProduct = true
    }

  },
}
</script>
