<template>
  <el-dialog fullscreen v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='dialogTitle'>
    <el-row>
      <el-col :span='24'>
        <el-form :model='searchData' inline>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchData.ym'
              type='month'
              value-format='yyyy-MM'
              format='yyyy年MM月'
              @change='handleQuery'
              placeholder='选择月份' style='width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label=''>
            <artist-search @handleSelect='selectArtist'></artist-search>
          </el-form-item>
          <el-form-item label='发起人'>
            <el-input v-model='searchData.leader_name' clearable placeholder='发起人：支持模糊搜索'></el-input>
          </el-form-item>
          <el-form-item label='状态'>
            <el-select v-model='searchData.progress_status' style='width: 120px' clearable>
              <el-option :label='option.label' :value='option.value' v-for='(option,key) in progressStatusOptions'
                         :key='key'
                         :style='`text-align: center;border-radius: 3px;margin:1px;border: #7d8086 1px solid;background-color:${option.color_value}`'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleQuery' icon='el-icon-search'>查询</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter='20'>
      <el-col :span='12'>
        <JoinToLb lb-type-title='选品表' :product-id='productId' :lb-type-code='`kol`' lb-type='1'
                  ref='JoinToLbKol'></JoinToLb>
      </el-col>
      <el-col :span='12'>
        <JoinToLb lb-type-title='直播表' :product-id='productId' :lb-type-code='`live`' lb-type='2'
                  ref='JoinToLbLive'></JoinToLb>

      </el-col>

    </el-row>
  </el-dialog>
</template>

<script>
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import JoinToLb from '@/pages/lb/product/join/JoinToLb'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'JoinLive',
  data() {
    return {
      dialogTitle: '产品添加到选品',
      searchData: { artist_id: null, ym: null, leader_name: null, progress_status: null, leader_id: null },
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      KolDataList: [],
      KolPagingData: {},
      LivePagingData: {},
      KolSelection: [],
      LiveDataList: []
    }
  },
  components: {
    ArtistSearch,
    JoinToLb,
    ArtistSearchCustom
  },
  props: {
    productId: {
      type: [Number, String]
    }
  },
  methods: {
    onOpen() {
      this.defaultTime()
      setTimeout(() => {
        this.handleQuery()
      }, 500)

    },
    onClose() {

    },
    defaultTime() {
      const end = new Date()
      //年份选择：默认当月
      // this.searchData.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
      this.$set(this.searchData, 'ym', end.getFullYear() + '-' + (end.getMonth() + 1))
    },

    selectArtist(selectInfo) {
      // console.log(selectInfo)
      if (selectInfo)
        this.searchData.artist_id = selectInfo.id||null
      else
        this.searchData.artist_id = null

      this.handleQuery()
    },

    handleQuery() {
      this.$nextTick(() => {
        if (this.$refs['JoinToLbKol'])
          this.$refs['JoinToLbKol'].handleQuery(this.searchData)
        if (this.$refs['JoinToLbLive'])
          this.$refs['JoinToLbLive'].handleQuery(this.searchData)
      })

    }


  }
}
</script>

<style scoped>

</style>
