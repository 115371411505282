<template>
  <div>
    <el-card class='box-card'>
      <div slot='header' class='clearfix'>
        <span>{{ lbTypeTitle }}</span>
        <el-button style='float: right; padding: 3px 0' type='text' @click='handleKolJoinBatch'>批量添加</el-button>
      </div>
      <div>
        <el-table :data='KolDataList' highlight-current-row border
                  @selection-change='handleSelectionChange'
                  @sort-change='changeSelectionTableSort'
        >
          <el-table-column type='selection' width='55'></el-table-column>
          <el-table-column type='index' label='序号' width='65' align='center'></el-table-column>
<!--          <el-table-column  label='排期ID' prop='id' width='100' align='center'></el-table-column>-->
          <el-table-column label='日期' prop='start_at' align='center' min-width='120' sortable='custom'>
            <template slot-scope='{row}'>
              <span>{{ $utils.parseTime(row.start_at, '{y}年{m}月{d}日') }}</span>
            </template>
          </el-table-column>
          <el-table-column label='红人' prop='nickname' align='center' min-width='120'
          ></el-table-column>
          <el-table-column label='平台' prop='platform_code' align='center' min-width='140'>
            <template slot-scope='{row}'>
              <span>{{ row.platform_name || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='链接' prop='start_at' align='center' min-width='260'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-link @click='routerLink(lbTypeCode,row.id)'>
                {{ row.date }} 【{{ row.nickname }}】{{ row.platform_name }} 选品
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label='操作' min-width='80' align='center' fixed='right'>
            <template slot-scope='{row}'>
              <el-button type='text' @click='handleKolJoin(row)' icon='el-icon-shopping-bag-1'
              >添加
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'JoinToLb',
  data() {
    return {
      KolDataList: [],
      searchData: {},
      orderSort: { start_at: 'desc' },
      KolSelection: []
    }
  },
  props: {
    lbType: {
      type: [Number, String],
      required: true
    },
    lbTypeCode: {
      type: [String],
      required: true
    },
    lbTypeTitle: {
      type: [String]
    },
    productId: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.KolSelection = val
    },
    changeSelectionTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    handleQuery(searchData) {
      this.searchData = searchData
      this.getList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchData.ym) {
        condition.ym = this.searchData.ym
      }
      if (this.searchData.artist_id) {
        condition.artist_id = this.searchData.artist_id
      }
      if (this.searchData.progress_status) {
        condition.progress_status = this.searchData.progress_status
      }
      if (this.searchData.leader_id) {
        condition.leader_id = this.searchData.leader_id
      }
      if (this.searchData.leader_name) {
        condition.leader_name = this.searchData.leader_name
      }
      return condition
    },
    async getList() {
      let searchData = this.handleSearchCondition()
      Object.assign(searchData, { type: this.lbType, order: this.orderSort })
      let { list } = await this.$api.getLbScheduleMonthList(searchData)
      this.KolDataList = list
    },
    isDing() {
      const ua = window.navigator.userAgent
      return ua.indexOf('DingTalk') !== -1 // true or false
    },
    routerLink(type, id) {
      let name = ''
      if (type === 'kol') {
        name = 'lbKolDetail'
      } else {
        name = 'lbLiveDetail'
      }

      if (this.isDing()) {
        this.$router.push({
          name: name,
          params: { id: id }
        })
      } else {
        const { href } = this.$router.resolve({
          name: name,
          params: { id: id }
        })
        window.open(href, '_blank')
      }
    },
    async handleKolJoin(row) {
      let postData = {
        lb_id: row.id,
        product_ids: [this.productId]
      }
      let id = await this.$api.appendLbScheduleProducts(postData)
      if (id) {
        this.$message.success('添加成功')
      }
    },
    async handleKolJoinBatch() {
      let ids = this.KolSelection.map((i) => {
        return i.id
      })
      for (const item of ids) {
        let postData = {
          lb_id: item,
          product_ids: [this.productId]
        }
        let id = await this.$api.appendLbScheduleProducts(postData)
        if (id) {
          this.$message.success('添加成功')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
