<template>
  <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' id='my-element' :destroy-on-close='true' @close='onClose'>
    <template slot='title'>
      <div style='height: 0'>
        <el-link class='pptTitle'>{{ formData.title }}</el-link>
        <svg-icon style='cursor: pointer' @click='changeFull'
                  :icon-class="fullscreen?'offScreen':'fullscreen'"></svg-icon>
        <b v-if='formData.updated_at' style='color: darkred'>（最后更新时间：{{ formData.updated_at }}）</b>
      </div>
    </template>
    <div :class="{'animate__animated': true, 'animate__fadeIn': showData}" class='preview-box'
         style='overflow:hidden;height: 90vh'>
      <div>
        <transition name='el-fade-in-linear' v-if='formData.state>0'>
          <el-image v-show='showStateImg' class='stateImg' :src='stateImg[`state_${formData.state}`]'></el-image>
        </transition>
        <el-row :gutter='24' class='base-info'>
          <el-col :span='18'>
            <div class='title'>
              <span>{{ formData.company_name }}</span>
            </div>
            <el-row>
              <el-col :span='4'>
                商品名称及规格
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.title }}({{ formData.spec_name }})</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                店铺评分
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.company_score || '/' }} 分</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                坑位费
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.ad_space_fee || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                线上/线下佣金
              </el-col>
              <el-col :span='20'>
                <span style="color: #F56C6C">{{ formData.online_cmn + '%' || '/' }} / {{ formData.offline_cmn + '%' || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                日常价
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.price || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                直播价
              </el-col>
              <el-col :span='20'>
                <span style="color: #F56C6C">{{ formData.lb_price || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                赠品
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.gift || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                是否全网统一机制
              </el-col>
              <el-col :span='20'>
                <span style="color: #F56C6C">{{ formData._unify || '/' }}</span> &nbsp;
                <span style="color: #F56C6C" v-if='formData.unify===2'>{{ formData.special_remark || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                是否有签独家
              </el-col>
              <el-col :span='20'>
                <span style="color: #F56C6C">{{ formData._exclusive || '/' }}</span> &nbsp;
                <span style="color: #F56C6C" v-if='formData.exclusive===1'>{{ formData.other_activity || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                是否可签保价1月
              </el-col>
              <el-col :span='20'>
                <span style="color: #F56C6C">{{ formData._support_value || '/' }}</span>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span='4'>
                历史销量
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.sales_volume || '/' }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='4'>
                备注
              </el-col>
              <el-col :span='20'>
                <span>{{ formData.remark || '/' }}</span>
              </el-col>
            </el-row>
            <el-row class='light-spot-box'>
              <div class='light-spot-title' @dblclick='editData("light")'>亮点卖点（简洁）
              </div>
              <div class='light-spot-content' v-html='formData.light_spot' @dblclick='editData("light")'>
              </div>
            </el-row>
          </el-col>
          <el-col :span='6'>
            <div class='cover-box' @dblclick="editImgBtn">
              <div class='cover-title'>
                商品图片
              </div>
              <div class='cover-view'>
                <el-image fit='cover' style='width:300px;height: 300px'
                          :src='formData.cover_url'>
                  <div slot='error' class='image-slot' style='width: 100%'>
                    <div class='image-slot'>
                      <!--<i class="el-icon-picture-outline"></i>-->
                      o(╥﹏╥)o
                      <br />
                      <span>暂无图片</span>
                    </div>
                  </div>
                </el-image>
              </div>
            </div>
            <div class='intro' @dblclick.prevent='editData("intro")'>
              {{ formData.intro }}
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      width='70%'
      :wrapperClosable='false'
      @close='closeEdit'
      :modal='false'
      :destroy-on-close='true'
      :visible.sync='introVisible'
      append-to-body>
      <template slot='title'>
            <span class='pptTitle' style='font-size: 18px'>
              修改{{ this.editType === 'intro' ? '品牌背书' : '产品亮点' }}</span>
        <el-button style='float: right;margin-right: 50px' @click='saveEdit' type='primary'>保存
        </el-button>
      </template>
      <ApeEditor
        v-if="this.editType!=='intro'&&introVisible"
        :editorHeight='500'
        :inline='true'
        :init-html='editContent'
        :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify | bullist numlist' +
          ' | outdent indent blockquote | removeformat anchor hr |' +
          ' code preview fullscreen'"
        @handleTinymceInput='handleTinymceInput'></ApeEditor>
      <el-input v-else type='textarea' v-model='editContent' :rows='30'></el-input>
    </el-dialog>
    <el-dialog :destroy-on-close="true" :visible.sync="editImgDialog" append-to-body title="商品图片替换" width="20%">
      <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                   uploadTip='请上传图片，最多上传1份。大小限制2M文件'
                   @handleUploadRemove='handleUploadRemove'
                   @handleUploadSuccess='handleUploadSuccess'></ApeUploader>
      <br/>
      <div style="text-align: center;">
        <el-button type="primary" @click="saveImg">提交</el-button>
        <el-button @click="cancelBtn">取消</el-button>
      </div>
    </el-dialog>
  </el-dialog>

</template>

<script>
import ApeEditor from '@/components/ApeEditor'
import state_1 from '@/assets/imgs/1.png'
import state_2 from '@/assets/imgs/2.png'
import state_3 from '@/assets/imgs/3.png'
import state_4 from '@/assets/imgs/4.png'
import ApeUploader from '@/components/ApeUploader'

export default {
  name: 'SelectionPreview',
  components: {
    ApeEditor,ApeUploader
  },
  computed: {},
  data() {
    return {
      formData: {},
      dataList: [],
      fullscreen: true,
      stateImg: {
        state_1: state_1,
        state_2: state_2,
        state_3: state_3,
        state_4: state_4
      },
      showStateImg: true,
      introVisible: false,
      editContent: '',
      editValue: '',
      editType: '',
      showData: true,
      uploadFileList:[],
      editImgDialog:false,
      editImg:{}
    }
  },
  props: {
    id: {
      type: [Number, String]
    }
  },
  methods: {
    async getInfo() {

      let { info } = await this.$api.getProductInfo(this.id)
      // info.brand_id = Number(info?.brand_id) || null
      // info.customer_id = Number(info?.customer_id) || null
      // // info.company_score = Number(info?.company_score) || null
      this.formData = info
      this.uploadFileList = [{ url: info.cover_url }]

    },
    closeEdit() {
      this.introVisible = false
    },
    editData(value) {
      this.introVisible = true
      this.editType = value
      if (value === 'intro') {
        this.editContent = this.formData.intro || ''

      } else {
        this.editContent = this.formData.light_spot || ''
      }
    },
    async onOpen() {
      this.fullscreen = localStorage.getItem('lbFullscreen') === 'true'
      this.fullscreen ? this.openFullscreen() : ''
      await this.getInfo()
    },
    onClose() {
      //记录关闭的时候 是全屏还是非全屏
      document.fullscreenElement !== null ? localStorage.setItem('lbFullscreen', 'true') : localStorage.setItem('lbFullscreen', 'false')
      this.closeFullscreen()
      this.$emit('close')
    },

    changeFull() {
      this.fullscreen = !this.fullscreen
      this.fullscreen ? this.openFullscreen() : this.closeFullscreen()
      localStorage.setItem('lbFullscreen', this.fullscreen)
    },
    async saveEdit() {
      if (this.editType === 'intro') {
        this.formData.intro = this.editContent || ''
        await this.editCellVal('formBelong', { intro: this.formData.intro, id: this.formData.id })
      } else {
        this.formData.light_spot = this.editContent || ''
        await this.editCellVal('formOther', { light_spot: this.formData.light_spot, id: this.formData.id })
      }

      this.introVisible = false
    }
    ,
    async editCellVal(name, data) {
      if (this.formData.id) {
        let id = await this.$api.saveProductPart(name, data)
        if (id) {
          this.$message.success('保存成功！')
          setTimeout(() => {
            this.showStateImg = true
          }, 600)
          this.$forceUpdate()
        }
      } else {
        this.$message.warning('参数错误，无法提交！！！')
      }
    }
    ,
    handleTinymceInput(val) {
      this.editContent = val
    }
    ,
    onchange(value) {
      this.editCellVal('state', value)
      this.formData.state = value
      this.showStateImg = false
    }
    ,
    close() {
      this.$emit('update:visible', false)
    }
    ,

    openFullscreen() {
      if (this.fullscreen) {
        if (document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled) {
          const element = document.getElementById('my-element')
          if (element.requestFullscreen) {
            element.requestFullscreen()
          } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen()
          } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen()
          } else if (element.msRequestFullscreen) { // Internet Explorer and Edge
            element.msRequestFullscreen()
          }
        }
      }
    }
    ,
    closeFullscreen() {
      if (document.exitFullscreen && document.fullscreenElement !== null) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { // Internet Explorer and Edge
        document.msExitFullscreen()
      }
    }
    ,
    handleFullscreenChange() {
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
      localStorage.setItem('lbFullscreen', this.fullscreen)

    },
    editImgBtn() {
      this.editImgDialog = true
    },
    async saveImg() {
      let postData = {
        id: this.formData.id,
        cover: this.editImg.cover,
        cover_url: this.editImg.full_path
      }
      let id = await this.$api.saveProductPart('formBase', postData)
      if (id) {
        this.$message.success('成功')
        this.formData.cover_url = this.editImg.full_path
        this.editImgDialog = false
      }
    },
    // 图片删除回调
    handleUploadRemove() {
      this.editImg = this.formData.cover_url
    },
    handleUploadSuccess(file) {
      this.editImg = {
        full_path: file.full_path,
        cover: file.id
      }
    },
    cancelBtn() {
      this.editImg = {}
      this.editImgDialog = false
    }

  },
  mounted() {
    document.addEventListener('fullscreenchange', this.handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', this.handleFullscreenChange) // Firefox
    document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange) // Chrome, Safari and Opera
    document.addEventListener('MSFullscreenChange', this.handleFullscreenChange) // Internet Explorer and Edge

  }
}
</script>

<style lang='scss' scoped>

.preview-box {
  color: black;
  height: 90%;
  overflow: hidden;

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 26px;
    background-color: #f2f2f2;
    padding: 10px;
  }

  .base-info {
    flex: 8;
    font-size: 16px;
    line-height: 24px;
    font-family: "Microsoft YaHei";

    .el-row {
      padding: 0 10px;
    }
  }

  .base-line {
    padding: 10px;
  }

  .light-spot-title {
    font-size: 28px;
    font-weight: bold;
    padding-top: 10px;
  }

  .light-spot-box {
    width: 100%;
    height: 53vh;
    background-color: #f2f2f2;
    text-align: left;
    vertical-align: top;

    padding: 0 10px;

    .light-spot-content {
      margin-top: 10px;

      > ::v-deep p {
        margin: 0 !important;
      }
    }
  }
}

.light-spot-edit {
  margin-top: 10px;
  height: 100%;
  overflow: scroll;
}


.cover-box {
  flex: 4;
  background-color: #F2F6FC;
  margin-left: 5px;
  width: 100%;
  min-height: 400px;
}

.cover-title {
  text-align: center;
  width: 100%;
  line-height: 30px;
  font-weight: bold;
  font-size: 28px;
  padding: 10px 0;
}

.cover-view {
  padding: 10px 0;
  //height: 50%;

  > * {
    width: 200px;
    display: block;
    margin: 0 auto;
    padding: 10px;
  }

}


.intro {
  min-height: 400px;
  max-height: 38rem;
  overflow-y: scroll;
  cursor: pointer;
}

.opt-box {
  width: 98%;
  //line-height: 40px;
  position: absolute;
  bottom: 2px;
  opacity: 0.3;
}

.opt-box:hover {
  opacity: 1;
}

.pptTitle {
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  /*border: 1px solid red;*/
}

.stateImg {
  width: 250px;
  height: 250px;
  position: absolute;
  display: block;
  right: 20%;
  z-index: 99;
  top: 0;
}

.image-slot {
  text-align: center;
  margin: 50% auto;
  color: #7a8491;
}


</style>
<style>
.tox-tinymce-aux {
  z-index: 999999 !important;
}
</style>
