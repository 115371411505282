<template>
  <div>
    <el-popover
        v-show="logisticData.length>0"
        v-model="morePopover"
        placement='right'
        popper-class='popperClass'
        trigger='manual'
        width='400'>
      <div class="popoverTitle"> 物流详情
        <span @click="handleClose"><i class="el-icon-close"></i></span>
      </div>
      <logistic-time-line :logisticData="logisticData"></logistic-time-line>
      <el-link slot="reference" style="font-size:12px;margin-left: 10px" type="primary"
               @click="handleOpen">
        {{ buttonTitle }}
      </el-link>
    </el-popover>
  </div>

</template>

<script>
import logisticTimeLine from '@/components/LogisticTimeLine.vue'

export default {
  name: 'LogisticInfo',
  props: {
    logisticData: {
      type: Array,
      default() {
        return []
      }
    },
    morePopover: {
      type: Boolean,
      default() {
        return false
      }
    },
    buttonTitle: {
      type: String,
      default() {
        return '查看'
      }
    }
  },
  components: {
    logisticTimeLine
  },
  methods: {
    handleClose() {
      this.$emit('update:morePopover', false)
    },
    handleOpen() {
      this.$emit('update:morePopover', true)
    }
  }
}
</script>

<style lang="scss" scoped>

.popoverTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;

  > span {
    font-size: 16px;
    float: right;
    color: red;
    cursor: pointer;
  }
}

.popperClass {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
