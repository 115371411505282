<template>
  <div style="width: 96%;margin: 0 auto">
    <el-row :gutter="20">
      <el-col :span="12" v-for='(item) in dataList' :key='item.id'>
        <el-card class='card_content' shadow='hover'>
          <el-skeleton animated :loading='loading'>
            <template slot='template'>
              <el-row>
                <el-col :span="9">
                  <el-skeleton-item variant='image' style='width: 280px; height: 280px;'/>
                </el-col>
                <el-col :span="15">
                  <div class="el-skeleton">
                    <el-skeleton-item variant='p' style='width: 50%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 100%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 100%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 20%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 80%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 80%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 60%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 80%'/>
                    <br/>
                    <el-skeleton-item variant='p' style='width: 100%'/>
                  </div>
                </el-col>
              </el-row>
              <!--              <div style='padding: 14px;'>-->
              <!--                <el-skeleton-item variant='p' style='width: 50%'/>-->
              <!--                <div>-->
              <!--                  <el-skeleton-item variant='text' />-->
              <!--                  <el-skeleton-item variant='text' style='width: 30%;'/>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
            <template>
              <el-row :gutter="20">
                <el-col :span="9">
                  <product-image :radius="true" style='max-height: 280px;margin: 0 auto;max-width: 280px;'
                                 :image-url='item.cover_url'/>
                </el-col>
                <el-col :span="15">
                  <div class="card_content">
                    <el-row>
                      <el-col :span="24" :title="item.title"><b>{{ item.title }}</b></el-col>
                    </el-row>
                    <el-row class="card_descriptions">
                      <el-col :title="item.brand_name" :span="6" style="text-align: left">
                        <span>{{ item.brand_name || '/' }}</span>
                        <a>品牌</a>
                      </el-col>
                      <el-col :title="item.spec_name" :span="6">
                        <span >{{ item.spec_name || '/' }} </span>
                        <a>规格</a>
                      </el-col>
                      <el-col :title="item.company_name" :span="6">
                        <span>{{ item.company_name || '/' }} </span>
                        <a>店铺名称</a>
                      </el-col>
                      <el-col :span="6" :title="item.platform_names">
                        <span>{{ item.platform_names || '/' }} </span>
                        <a>可播平台</a>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 14px ">
                      <el-col>
                        赠品：{{ item.gift || '/' }}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin-top: 10px">
                      <el-col class="tags" :span="8">
                        <span>坑位费：<a>{{ item.ad_space_fee || '暂无' }}</a></span>
                      </el-col>
                      <el-col class="tags" :span="8">
                        <span>线上佣金(%)：<a>{{ item.online_cmn ? item.online_cmn : '' || '暂无' }}</a></span>
                      </el-col>
                      <el-col class="tags" :span="8">
                        <span>线下佣金(%)：<a>{{ item.offline_cmn ? item.offline_cmn : '' || '/' }}</a></span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" style="margin-top: 10px">
                      <el-col class="tags" :span="8">
                        <span>是否统一机制：<span>{{ item.exclusive === 1 ? '是' : '否' }}</span></span>
                      </el-col>
                      <el-col class="tags" :span="8">
                        <span>是否有签独家：<span>{{ item.exclusive === 1 ? '是' : '否' }}</span></span>
                      </el-col>
                      <el-col class="tags" :span="8">
                        <span>是否签保价协议：<span>{{ item.support_value === 1 ? '是' : '否' || '/' }}</span></span>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px">
                      <el-col :span="5">负责人：{{ item.leader_name || '/' }}</el-col>
                      <el-col :span="5">库存：{{ item.qty || '/' }}</el-col>
                      <el-col :span="10">备注：{{ item.remark || '' }}</el-col>
                    </el-row>
                    <el-row style="margin-top: 10px ">
                      <el-col :span="18" class="price_box">
                        <div class="price" :title="item.price"><span>日常价￥</span>{{ item.price || '' }}</div>
                        <div class="lb_price" :title="item.lb_price"><span>直播价￥</span>{{ item.lb_price || '' }}</div>
                      </el-col>
                    </el-row>
                    <el-row style="margin-top: 10px ">
                      <el-col class="buttons">
                        <el-link size='medium' type='text' icon='el-icon-edit' @click='handleEdit(item)'  v-if="userPermissions.indexOf('product_edit')>-1">修改</el-link>
                        <el-link size='medium' icon='el-icon-folder-checked' type='text' @click='handleJoinLive(item)'>
                          选品
                        </el-link>
                        <el-link size='medium' type='text' icon='el-icon-document' @click='handleDetails(item)'>详情
                        </el-link>
                                                <el-link size='medium' type='text' icon='el-icon-s-platform' @click='handleCheck(item)'>PPT
                                                </el-link>
                        <product-version-list v-if='userPermissions.indexOf("product_version")>-1' :key='item.id'
                                              :product-id='item.id' :product-name='item.title'
                                              style='margin-left: 5px'/>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-skeleton>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductImage from './ProductImage'
import ProductVersionList from './history/list.vue'

export default {
  name: 'ProductCard',
  components: { ProductImage, ProductVersionList },
  props: {
    dataList: {
      type: Array
    },
    loading: {
      type: Boolean
    }

  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    handleEdit(row) {
      this.$emit('handleEdit', row)
    },
    handleJoinLive(row) {
      this.$emit('handleJoinLive', row)
    },
    handleCheck(row) {
      this.$emit('handleCheck', row)
    },
    handleDetails(row) {
      this.$emit('handleDetails', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.card_content {
  margin-bottom: 20px;
  cursor: pointer;
}

//
.el-skeleton {
  > * {
    margin: 6px 0;
  }
}

.card_content {
  height: 340px;

  > * > * > b {
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }

  .card_descriptions {
    margin-top: 10px;
    height: 40px;

    > * {
      border-right: 1px solid $--border-base-color;
      height: 40px;
      text-align: center;
      color: $--color-text-secondary;

      > span {
        color: $--color-text-regular;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }

      > a {
        display: block;
        margin-top: 10px;
      }
    }
  }

  .tags {
    > span {
      background-color: #f7f7f7;
      color: $--color-text-secondary;
      padding: 6px;
      border-radius: 4px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      > a {
        color: $--color-pure-red;
      }

      > span {
        color: $--color-text-primary;
      }
    }
  }

  .price_box {
    border: 1px solid $--color-pure-red;
    height: 32px;
    text-align: center;
    border-radius: 6px;
    display: flex;
    color: $--color-pure-red;

    .price, .lb_price {
      width: 50%;
      height: 100%;
      line-height: 32px;
      font-size: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      > span {

        font-size: 16px
      }
    }

    .price {
      color: $--color-pure-red;
    }

    .lb_price {
      color: #FFFFFF;
      background-color: $--color-pure-red;
    }
  }

  .buttons {
    > * {
      margin-right: 20px;
      color: black;
      width: 60px;
      font-size: 14px;
      padding: 4px;
      text-align: left;
    }
  }
}
</style>
